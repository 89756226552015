import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Technical.module.scss"
import merTick from "../../images/hireMernStack/mernTick.svg"

const Technical = ({ strapiData }) => {
  return (
    <div className={`${styles.mernTechServices}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div className={styles.cardBg}></div>
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards?.slice(0, 6).map((e, i) => (
            <Col
              lg={4}
              xl={4}
              md={6}
              sm={12}
              xs={12}
              key={i}
              className="gap-30 d-flex"
            >
              <div className={styles.card}>
                <div className="d-flex">
                  <div>
                    <img src={merTick} alt="tick" decoding="async" loading="lazy" />
                  </div>
                  <div>
                    <h3>{e?.title}</h3>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Technical
