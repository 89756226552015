// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--fcb6d";
export var clientDots2 = "Testimonials-module--clientDots2--8051e";
export var content = "Testimonials-module--content--638f0";
export var dots3 = "Testimonials-module--dots3--dded6";
export var hireArrow = "Testimonials-module--hireArrow--dc8a0";
export var iconContainer = "Testimonials-module--iconContainer--bf35a";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--3f44d";
export var imgBtn = "Testimonials-module--imgBtn--0e498";
export var person = "Testimonials-module--person--61b2b";
export var personBg = "Testimonials-module--personBg--8374a";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--0827a";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--98f03";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--96df7";
export var row = "Testimonials-module--row--d7cba";
export var section = "Testimonials-module--section--1eeb2";
export var staffRec = "Testimonials-module--staffRec--6e27b";
export var testName = "Testimonials-module--testName--a6ae6";
export var testimonials = "Testimonials-module--testimonials--2ea02";
export var testimonialsContent = "Testimonials-module--testimonialsContent--994e9";
export var vector2 = "Testimonials-module--vector2--d4143";