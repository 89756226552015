import { Link } from "gatsby"
import React from "react"
import * as styles from "./MernArmy.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import mernIcon from "../../images/hireMernStack/armyIcon.svg"
const MernArmy = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.mernArmyMain}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <Row className={styles.cardWrapper}>
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col lg={4} md={6} sm={12} xs={12} key={i}>
                  <div
                    className={
                      i === 0
                        ? styles.card1
                        : i === 1
                        ? styles.card2
                        : i === 2
                        ? styles.card3
                        : ""
                    }
                  >
                    <img
                      src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                      alt={e?.title}
                      decoding="async"
                      loading="lazy"
                      className={styles.developer}
                    />
                    <div
                      className={`d-flex justify-content-between ${styles.imageStars}`}
                    >
                      <div>
                        <h3>{e?.title}</h3>
                      </div>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                    <div className={styles.armyBtn}>
                      <Link to="#mernForm">
                        <Button className={styles.mernBtn}>
                          <img
                            src={mernIcon}
                            alt=""
                            className={styles.mernIcon}
                          />
                          {i === 0
                            ? "Hire Hamza Sajid"
                            : i === 1
                            ? "Hire Safdar Abbas"
                            : "Hire Nadeem Aslam"}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MernArmy
