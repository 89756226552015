import { Link } from "gatsby"
import React from "react"
import * as styles from "./HireMern.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import firstHire from "../../images/hireMernStack/firstHire.svg"
import secondHire from "../../images/hireMernStack/secondHire.svg"
import thirdHire from "../../images/hireMernStack/thirdHire.svg"
import fourthHire from "../../images/hireMernStack/fourthHire.svg"
const HireMern = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.hireMernMain}>
        <Container className={styles.hireMainContainer}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={styles.cardWrapper}>
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col
                  lg={3}
                  md={6}
                  sm={12}
                  xs={12}
                  key={i}
                  className="d-flex justify-content-center"
                >
                  <div
                    className={
                      i === 0
                        ? styles.card1
                        : i === 1
                        ? styles.card2
                        : i === 2
                        ? styles.card3
                        : styles.card
                    }
                  >
                    <img
                      src={
                        i === 0
                          ? firstHire
                          : i === 1
                          ? secondHire
                          : i === 2
                          ? thirdHire
                          : i === 3
                          ? fourthHire
                          : ""
                      }
                      alt={e?.title}
                      decoding="async"
                      loading="lazy"
                    />
                    <h3>{e?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </Col>
              ))}

            <div className={styles.mernBtn}>
              {strapiData?.buttons[0] && (
                <Link
                  to={`/${strapiData?.buttons[0]?.url}/`}
                  className="btn_black_border"
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HireMern
