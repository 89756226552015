import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Trending.module.scss"
import Row from "react-bootstrap/Row"

const Trending = ({ strapiData }) => {
  return (
    <div className="TechStacksTabsMern">
      <div className={styles.techStackglobalMern}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className={styles.trendingCardsContent}>
            <Row className={styles.trendingIcon}>
              {strapiData?.cards &&
                strapiData?.cards?.map((e, i) => (
                  <div className={` ${styles.techIconssCard}`} key={i}>
                    <div className={styles.technologyStacksIcon}>
                      <div className={styles.techStackImg}>
                        <img
                          src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                          alt={e?.title}
                          decoding="async"
                          loading="lazy"
                        />
                      </div>
                      <h3>{e?.title}</h3>
                    </div>
                  </div>
                ))}
            </Row>
          </div>
        </Container>
      </div>
    </div>
  )
}
export default Trending
