// extracted by mini-css-extract-plugin
export var SliderWidth = "GetDevelopers-module--SliderWidth--ffa49";
export var arrowImg = "GetDevelopers-module--arrowImg--e4e71";
export var card = "GetDevelopers-module--card--3c4c5";
export var cardImg = "GetDevelopers-module--cardImg--696b4";
export var cardMian = "GetDevelopers-module--cardMian--31544";
export var cardWrapper = "GetDevelopers-module--cardWrapper--3ca45";
export var cards = "GetDevelopers-module--cards--a3ec9";
export var description = "GetDevelopers-module--description--8fd07";
export var heading = "GetDevelopers-module--heading--7b006";
export var headingPremium = "GetDevelopers-module--headingPremium--3acef";
export var mainWorkMernStack = "GetDevelopers-module--mainWorkMernStack--e5802";
export var mernIconContainer = "GetDevelopers-module--mernIconContainer--c4688";
export var mernIconContainerLeft = "GetDevelopers-module--mernIconContainerLeft--7f1c9";
export var portfolioArrowIcon = "GetDevelopers-module--portfolioArrowIcon--5c820";
export var portfolioArrowIconCover = "GetDevelopers-module--portfolioArrowIconCover--2e3d6";
export var portfolioArrowRightIconCover = "GetDevelopers-module--portfolioArrowRightIconCover--ec8f6";
export var premiumImg = "GetDevelopers-module--premiumImg--7c849";
export var providingImg = "GetDevelopers-module--providingImg--67fb6";
export var teamButton = "GetDevelopers-module--teamButton--c471e";
export var trailBg = "GetDevelopers-module--trailBg--1752e";