// extracted by mini-css-extract-plugin
export var aurther = "Testimonials2-module--aurther--b2181";
export var circleTes = "Testimonials2-module--circleTes--58f54";
export var content = "Testimonials2-module--content--52ccc";
export var des = "Testimonials2-module--des--79f38";
export var iconContainer = "Testimonials2-module--iconContainer--0f7df";
export var iconContainerLeft = "Testimonials2-module--iconContainerLeft--e57f6";
export var personBg = "Testimonials2-module--personBg--67430";
export var portfolioArrowIcon = "Testimonials2-module--portfolioArrowIcon--7f313";
export var portfolioArrowIconCover = "Testimonials2-module--portfolioArrowIconCover--c17c2";
export var portfolioArrowRightIconCover = "Testimonials2-module--portfolioArrowRightIconCover--24094";
export var row = "Testimonials2-module--row--cd855";
export var section = "Testimonials2-module--section--478cd";
export var tesBtn = "Testimonials2-module--tesBtn--bdb76";
export var tesDots = "Testimonials2-module--tesDots--e2ff3";
export var tesRec = "Testimonials2-module--tesRec--2759f";
export var testimonials = "Testimonials2-module--testimonials--da7fb";