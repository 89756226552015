import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./TechStacks.module.scss"

const data = [
  {
    title: "Library",
    tech: [
      {
        name: "ExpressJS",
      },
      {
        name: "NestJS",
      },
      {
        name: "Fastify",
      },
      {
        name: "Flux",
      },
      {
        name: "MobX",
      },
      {
        name: "Couchbase",
      },
      {
        name: "SailsJS",
      },
      {
        name: "node cron",
      },
      {
        name: "PM2",
      },
      {
        name: "Passport",
      },
      {
        name: "Nodemailer",
      },
      {
        name: "Lodash",
      },
      {
        name: "Winston",
      },
      {
        name: "Redux",
      },
      {
        name: "Axios/ Fetch",
      },
      {
        name: "React-router",
      },
      {
        name: "React Hooks",
      },
      {
        name: "moment",
      },
      {
        name: "redux-saga",
      },
      {
        name: "MaterialUI",
      },
      {
        name: "ReactStrap",
      },
      {
        name: "Validation Library",
      },
    ],
  },
  {
    title: "Database",
    tech: [
      {
        name: "PostgresSQL",
      },
      {
        name: "MySQL",
      },
      {
        name: "Redis",
      },
      {
        name: "MongoDB",
      },
      {
        name: "FireBase",
      },
    ],
  },
  {
    title: "Tools & Utilities",
    tech: [
      {
        name: "WSGI",
      },
      {
        name: "Celery",
      },
      {
        name: "SOAP",
      },
      {
        name: "Redis",
      },
      {
        name: "Fabric",
      },
      {
        name: "Whoosh",
      },
      {
        name: "Highcharts JS",
      },
      {
        name: "JSON",
      },
      {
        name: "GIT",
      },
      {
        name: "NPM",
      },
      {
        name: "Gulp",
      },
      {
        name: "JSON",
      },
      {
        name: "Mocha",
      },
      {
        name: "REST",
      },
    ],
  },
  {
    title: "Framework",
    tech: [
      {
        name: "NextJS",
      },
      {
        name: "Express.js",
      },
      {
        name: "Meteor.js",
      },
      {
        name: "Socket.io",
      },
      {
        name: "Koa.js",
      },
    ],
  },
  {
    title: "Web stacks",
    tech: [
      {
        name: "PHP",
      },
      {
        name: "NestJS",
      },
      {
        name: "CodeIgniter",
      },
      {
        name: "Laravel",
      },
      {
        name: "MERN",
      },
      {
        name: "MEAN",
      },
      {
        name: "LAMP",
      },
      {
        name: "WordPress",
      },
      {
        name: "Magento",
      },
      {
        name: "WOO",
      },
      {
        name: "Shopify",
      },
      {
        name: "Zend",
      },
      {
        name: "Codeigniter",
      },
      {
        name: "Symfony",
      },
      {
        name: "Material Design",
      },
      {
        name: "CakePHP",
      },
      {
        name: "Bootstrap",
      },
      {
        name: "CSS3",
      },
      {
        name: "PEAN",
      },
      {
        name: "PERN",
      },
      {
        name: "JAM",
      },
      {
        name: "MEVN",
      },
      {
        name: "PEVN",
      },
    ],
  },
  {
    title: "Relational Mapping",
    tech: [
      {
        name: "Typeform",
      },
      {
        name: "Mongoose",
      },
      {
        name: "Sequelize",
      },
      {
        name: "Prisma",
      },
    ],
  },
  {
    title: "Server",
    tech: [
      {
        name: "Hetzner",
      },
      {
        name: "MS Azure",
      },
    ],
  },
]

const TechStacks = ({ strapiData }) => {
  return (
    <div className={`${styles.techStacksMern}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        {data &&
          data?.map((e, i) => (
            <div className={styles.stacks}>
              <div
                className={styles.frame}
                dangerouslySetInnerHTML={{
                  __html: e?.title,
                }}
              />
              <div className={styles.tech}>
                {e?.tech &&
                  e?.tech?.map((el, i) => (
                    <h3
                      className={
                        i === 1
                          ? styles.name1
                          : i === 3
                          ? styles.name3
                          : i === 5
                          ? styles.name5
                          : i === 7
                          ? styles.name7
                          : i === 9
                          ? styles.name9
                          : styles.name
                      }
                    >
                      {el?.name}
                    </h3>
                  ))}
              </div>
            </div>
          ))}
      </Container>
    </div>
  )
}

export default TechStacks
