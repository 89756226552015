import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import * as styles from "./TechStacksMobile.module.scss"
import "./TechMobile.scss"
const TechStacksMobile = ({ strapiData }) => {
  const data = [
    {
      title: "Library",
      tech: [
        {
          name: "ExpressJS",
        },
        {
          name: "NestJS",
        },
        {
          name: "Fastify",
        },
        {
          name: "Flux",
        },
        {
          name: "MobX",
        },
        {
          name: "Couchbase",
        },
        {
          name: "SailsJS",
        },
        {
          name: "PM2",
        },
        {
          name: "Passport",
        },
        {
          name: "Nodemailer",
        },
        {
          name: "Lodash",
        },
        {
          name: "Winston",
        },
        {
          name: "Redux",
        },
        {
          name: "Axios/ Fetch",
        },
        {
          name: "React-router",
        },
        {
          name: "React Hooks",
        },
        {
          name: "moment",
        },
        {
          name: "redux-saga",
        },
        {
          name: "MaterialUI",
        },
        {
          name: "ReactStrap",
        },
        {
          name: "Validation Library",
        },
      ],
    },
    {
      title: "Database",
      tech: [
        {
          name: "PostgresSQL",
        },
        {
          name: "MySQL",
        },
        {
          name: "Redis",
        },
        {
          name: "MongoDB",
        },
        {
          name: "FireBase",
        },
      ],
    },
    {
      title: "Tools & Utilities",
      tech: [
        {
          name: "WSGI",
        },
        {
          name: "Celery",
        },
        {
          name: "SOAP",
        },
        {
          name: "Redis",
        },
        {
          name: "Fabric",
        },
        {
          name: "Whoosh",
        },
        {
          name: "Highcharts JS",
        },
        {
          name: "JSON",
        },
        {
          name: "GIT",
        },
        {
          name: "NPM",
        },
        {
          name: "Gulp",
        },
        {
          name: "JSON",
        },
        {
          name: "Mocha",
        },
        {
          name: "REST",
        },
      ],
    },
    {
      title: "Framework",
      tech: [
        {
          name: "NextJS",
        },
        {
          name: "Express.js",
        },
        {
          name: "Meteor.js",
        },
        {
          name: "Socket.io",
        },
        {
          name: "Koa.js",
        },
      ],
    },
    {
      title: "Web stacks",
      tech: [
        {
          name: "PHP",
        },
        {
          name: "NestJS",
        },
        {
          name: "CodeIgniter",
        },
        {
          name: "Laravel",
        },
        {
          name: "MERN",
        },
        {
          name: "MEAN",
        },
        {
          name: "LAMP",
        },
        {
          name: "WordPress",
        },
        {
          name: "Magento",
        },
        {
          name: "WOO",
        },
        {
          name: "Shopify",
        },
        {
          name: "Zend",
        },
        {
          name: "Codeigniter",
        },
        {
          name: "Symfony",
        },
        {
          name: "Material Design",
        },
        {
          name: "CakePHP",
        },
        {
          name: "Bootstrap",
        },
        {
          name: "CSS3",
        },
        {
          name: "PEAN",
        },
        {
          name: "PERN",
        },
        {
          name: "JAM",
        },
        {
          name: "MEVN",
        },
        {
          name: "PEVN",
        },
      ],
    },
    {
      title: "Relational Mapping",
      tech: [
        {
          name: "Typeform",
        },
        {
          name: "Mongoose",
        },
        {
          name: "Sequelize",
        },
        {
          name: "Prisma",
        },
      ],
    },
    {
      title: "Server",
      tech: [
        {
          name: "Hetzner",
        },
        {
          name: "MS Azure",
        },
      ],
    },
  ]
  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  return (
    <div className={styles.techMobile}>
      <div className={styles.globalTech}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`techAccord ${
                      e?.title === activeAccordion
                        ? "active-accordion"
                        : "second-accordion"
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      e?.title !== activeAccordion ? { marginTop: "24px" } : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`cardBody`}>
                      <div className={styles.techMobile}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <h3
                              className={
                                i === 1
                                  ? styles.name1
                                  : i === 3
                                  ? styles.name3
                                  : i === 5
                                  ? styles.name5
                                  : i === 7
                                  ? styles.name7
                                  : i === 9
                                  ? styles.name9
                                  : styles.name
                              }
                            >
                              {el?.name}
                            </h3>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default TechStacksMobile
