import React from "react"
import * as styles from "./GetDevelopersMobile.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const GetDevelopersMobile = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.mobileGetDev}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={styles.cardWrapper}>
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col lg={6} md={6} xs={12} key={i}>
                  <div className={styles.card}>
                    <div className={styles.cardImg}>
                      <img
                        src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                        alt={e?.title}
                        decoding="async"
                        loading="lazy"
                      />
                    </div>

                    <h3>{e?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GetDevelopersMobile
