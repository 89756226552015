// extracted by mini-css-extract-plugin
export var armyBtn = "MernArmy-module--armyBtn--a2e55";
export var card1 = "MernArmy-module--card1--eff74";
export var card2 = "MernArmy-module--card2--39e80";
export var card3 = "MernArmy-module--card3--60aa7";
export var cardWrapper = "MernArmy-module--cardWrapper--1da0f";
export var description = "MernArmy-module--description--33140";
export var developer = "MernArmy-module--developer--d65b5";
export var heading = "MernArmy-module--heading--881b2";
export var imageStars = "MernArmy-module--imageStars--fcfc8";
export var mernArmyMain = "MernArmy-module--mernArmyMain--dd602";
export var mernBtn = "MernArmy-module--mernBtn--e6117";
export var mernIcon = "MernArmy-module--mernIcon--ee532";