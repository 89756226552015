// extracted by mini-css-extract-plugin
export var description = "TechStacks-module--description--09545";
export var frame = "TechStacks-module--frame--c0763";
export var heading = "TechStacks-module--heading--227d1";
export var name = "TechStacks-module--name--e060c";
export var name1 = "TechStacks-module--name1--ebdab";
export var name3 = "TechStacks-module--name3--5d5bb";
export var name5 = "TechStacks-module--name5--0dd12";
export var name7 = "TechStacks-module--name7--f66b4";
export var name9 = "TechStacks-module--name9--14687";
export var stacks = "TechStacks-module--stacks--ffe07";
export var tech = "TechStacks-module--tech--f9a17";
export var techStacksMern = "TechStacks-module--techStacksMern--fb5e2";