// extracted by mini-css-extract-plugin
export var description = "TechStacksMobile-module--description--a4119";
export var globalTech = "TechStacksMobile-module--globalTech--cf028";
export var heading = "TechStacksMobile-module--heading--7192b";
export var name = "TechStacksMobile-module--name--24150";
export var name1 = "TechStacksMobile-module--name1--c5b55";
export var name3 = "TechStacksMobile-module--name3--2b4dc";
export var name5 = "TechStacksMobile-module--name5--e8315";
export var name7 = "TechStacksMobile-module--name7--3ab57";
export var name9 = "TechStacksMobile-module--name9--30d3d";
export var techMobile = "TechStacksMobile-module--techMobile--a4f98";