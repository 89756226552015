import React from "react"
import * as styles from "./WhyHire.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const WhyHire = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.whyHireMain}>
        <Container className={styles.whyHireContainer}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={styles.cradWrapper}>
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col lg={6} md={6} sm={12} xs={12} key={i}>
                  <div className={styles.card}>
                    <h3>{e?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WhyHire
