import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import WorkPortfolio from "../components/common/WorkPortfolio"
import Banner from "../components/mern-stack/Banner"
import Pricing from "../components/mern-stack/Pricing"
import TeckStacks from "../components/mern-stack/TeckStacks"
import MainLayout from "../layouts/MainLayout"
import GetDevelopers from "../components/mern-stack/GetDevelopers"
import GetDevelopersMobile from "../components/mern-stack/GetDevelopersMobile"
import WhyHire from "../components/mern-stack/WhyHire"
import MernArmy from "../components/mern-stack/MernArmy"
import HireMern from "../components/mern-stack/HireMern"
import Technical from "../components/mern-stack/Technical"
import Industry from "../components/mern-stack/Industry"
import Trending from "../components/mern-stack/Trending"
import ProfessionalsMern from "../components/mern-stack/ProfessionalsMern"
import Testimonials from "../components/mern-stack/Testimonials"
import Testimonials2 from "../components/mern-stack/Testimonials2"
import Statics from "../components/mern-stack/Statics"
import TechStacksMobile from "../components/mern-stack/TechStacksMobile"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const mernStack = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const why = data?.strapiPage?.sections[1]
  const easy = data?.strapiPage?.sections[2]
  const meet = data?.strapiPage?.sections[3]
  const hireMern = data?.strapiPage?.sections[4]
  const techi = data?.strapiPage?.sections[5]
  const pricing = data?.strapiPage?.sections[6]
  const industry = data?.strapiPage?.sections[7]
  const tech = data?.strapiPage?.sections[8]
  const trending = data?.strapiPage?.sections[9]
  const professional = data?.strapiPage?.sections[10]
  const portfolio = data?.strapiPage?.sections[11]
  const clients = data?.strapiPage?.sections[12]
  const faqs = data?.strapiPage?.sections[13]
  const testimonials = data?.testimonials?.sections[0]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner
        strapiData={heroSection}
        breadCrumb={data?.strapiPage?.buttons}
        pageName="Hire MernStack Developer"
      />
      <Statics />
      {!isMobile && !isTablet ? (
        <GetDevelopers strapiData={why} />
      ) : (
        <GetDevelopersMobile strapiData={why} />
      )}
      <WhyHire strapiData={easy} />
      <MernArmy strapiData={meet} />
      <HireMern strapiData={hireMern} />
      <Technical strapiData={techi} />
      <Pricing strapiData={pricing} />
      <Industry strapiData={industry} />
      {!isMobile && !isTablet ? (
        <TeckStacks strapiData={tech} />
      ) : (
        <TechStacksMobile strapiData={tech} />
      )}

      <Trending strapiData={trending} />
      <ProfessionalsMern strapiData={professional} />
      <WorkPortfolio strapiData={portfolio} />
      {!isMobile && !isTablet ? (
        <Testimonials
          strapiData={testimonials}
          testiHead={clients.title}
          testiSubtitle={clients.subTitle}
        />
      ) : (
        <Testimonials2
          strapiData={testimonials}
          testiHead={clients.title}
          testiSubtitle={clients.subTitle}
        />
      )}
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "mern" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default mernStack
