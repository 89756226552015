import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import tick from "../../images/hireMernStack/tick.png"
import * as styles from "./Banner.module.scss"
import FaqForm from "../common/hireDeveloperForm"
import hand from "../../images/hireMernStack/hand.svg"

const cardData = [
  {
    img: tick,
    dec: "97% Customer Success Rate",
  },
  {
    img: tick,
    dec: "10+ Years Of Experience",
  },
  {
    img: tick,
    dec: "Free Consultation",
  },
  {
    img: tick,
    dec: "300+ Projects",
  },
]

const Banner = ({ strapiData, pageName }) => {
  return (
    <div id="mernbanner" className={styles.mernBanner}>
      <Container>
        <Row>
          <Col xl={6} md={12} lg={6}>
            <div className={styles.heading}>
              <h1
                className={styles.bannerHeading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
              <div className={styles.handDiv}>
                <p className={styles.arrowHead}>
                  HIRE YOUR MERN STACK TEAM TODAY
                </p>
                <img className={`px-2 ${styles.handImg}`} src={hand} alt="" />
              </div>
            </div>
            <div className={`${styles.story} d-flex`}>
              {cardData?.map((el, i) => (
                <div className={styles.success} key={i}>
                  <img src={tick} alt="icon" />
                  <p>{el?.dec}</p>
                </div>
              ))}
            </div>
          </Col>
          <Col xl={6} md={12} lg={6} id="mernForm" className={styles.form}>
            <FaqForm pageName={pageName} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
