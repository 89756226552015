// extracted by mini-css-extract-plugin
export var card = "HireMern-module--card--c3aab";
export var card1 = "HireMern-module--card1--2f770";
export var card2 = "HireMern-module--card2--3c966";
export var card3 = "HireMern-module--card3--e8d11";
export var cardWrapper = "HireMern-module--cardWrapper--08bbc";
export var description = "HireMern-module--description--9d51a";
export var heading = "HireMern-module--heading--09007";
export var hireImageLine = "HireMern-module--hireImageLine--515d0";
export var hireMainContainer = "HireMern-module--hireMainContainer--b1a57";
export var hireMernMain = "HireMern-module--hireMernMain--d5004";
export var mernBtn = "HireMern-module--mernBtn--0b654";