// extracted by mini-css-extract-plugin
export var card1 = "Industry-module--card1--56911";
export var card10 = "Industry-module--card10--54ee8";
export var card11 = "Industry-module--card11--26af2";
export var card2 = "Industry-module--card2--57f75";
export var card3 = "Industry-module--card3--56025";
export var card4 = "Industry-module--card4--403a0";
export var card5 = "Industry-module--card5--8e827";
export var card6 = "Industry-module--card6--f99d1";
export var card7 = "Industry-module--card7--2b526";
export var card8 = "Industry-module--card8--e53ba";
export var card9 = "Industry-module--card9--28338";
export var cardBg = "Industry-module--cardBg--08f22";
export var cardWrapper = "Industry-module--cardWrapper--23444";
export var description = "Industry-module--description--07e25";
export var heading = "Industry-module--heading--1daa6";
export var mernIndustryServices = "Industry-module--mernIndustryServices--2af04";