// extracted by mini-css-extract-plugin
export var cardBg = "Trending-module--cardBg--f01cf";
export var cards = "Trending-module--cards--c0215";
export var description = "Trending-module--description--d625e";
export var heading = "Trending-module--heading--ad788";
export var infaHeading = "Trending-module--infaHeading--674a3";
export var nav = "Trending-module--nav--9eee4";
export var navItem = "Trending-module--nav-item--23c4e";
export var navbarBlock = "Trending-module--navbarBlock--ee313";
export var tabData = "Trending-module--tabData--abb78";
export var tech = "Trending-module--tech--dd240";
export var techIconssCard = "Trending-module--techIconssCard--2de4a";
export var techInfrastructures = "Trending-module--techInfrastructures--0a4e2";
export var techStackImg = "Trending-module--techStackImg--db0cf";
export var techStackglobalMern = "Trending-module--techStackglobalMern--05798";
export var technologyStacksIcon = "Trending-module--technologyStacksIcon--27537";
export var trendingCardsContent = "Trending-module--trendingCardsContent--08ff3";
export var trendingIcon = "Trending-module--trendingIcon--e8fae";